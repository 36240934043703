import { FlexProps } from '@chakra-ui/react';
import useFeatureFlag from 'src/components/feature-flags/useFeatureFlag';
import { useMenuContext } from '../MenuContext';
import NavButton from './components/NavButton';
import NavButtonsContainer from './components/NavButtonsContainer';

const buttonContainerProps = {
  position: 'absolute',
} as Partial<FlexProps>;

export function NavContainer() {
  const [menuState] = useMenuContext();
  const isMyShowsEnabled = useFeatureFlag('my-shows');

  return menuState === 'open' ? (
    <NavButtonsContainer mb="12px">
      <NavButton page="settings" />
      <NavButton page="help-center" />
      {isMyShowsEnabled && <NavButton page="my-shows" />}
    </NavButtonsContainer>
  ) : (
    <NavButtonsContainer {...buttonContainerProps} bottom="110px">
      <NavButton page="settings" px="13px" />
      <NavButton page="help-center" px="13px" />
      {isMyShowsEnabled && <NavButton page="my-shows" px="13px" />}
    </NavButtonsContainer>
  );
}
