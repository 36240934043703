import { generatePath, Navigate, Route, useParams } from 'react-router-dom';
import AdminRoute from 'src/components/routes/AdminRoute';
import {
  AdminFiles,
  AdminHostedShows,
  AdminHostingLimitOffenders,
  AdminJobs,
  AdminSupportDashboard,
  AdminUniversalStorage,
  AdminUsers,
  AudioEditor,
  CallRecorder,
  ClipGenie,
  CreateEpisode,
  EpisodeBuilder,
  Episodes,
  ForgotPassword,
  Library,
  Login,
  MyAccount,
  NoSubscription,
  PaymentSuccess,
  Personalise,
  Publish,
  Renew,
  RenewProcessing,
  ResetPassword,
  Settings,
  Signup,
  SignupProcessing,
  Statistics,
  UniversalLibrary,
  Upload,
} from 'src/components/routes/LazyLoadedComponents';
import PrivateRoute from 'src/components/routes/PrivateRoute';
import PublicRoute from 'src/components/routes/PublicRoute';

// Enables redirecting while preserving the url params
function NavigateWithParams({ to, ...props }: { to: string }) {
  const params = useParams();

  return <Navigate {...props} to={generatePath(to, params)} />;
}

export default [
  <Route
    key="password-reset"
    path="/password-reset/:token"
    element={<PublicRoute component={ResetPassword} />}
  />,
  <Route key="login" path="/login" element={<PublicRoute component={Login} />} />,
  <Route key="forgot" path="/forgot" element={<PublicRoute component={ForgotPassword} />} />,
  <Route key="signup" path="/signup" element={<PublicRoute component={Signup} />} />,
  <Route
    key="signup-processing"
    path="/signup/processing"
    element={<PublicRoute component={SignupProcessing} shouldRedirect={false} />}
  />,
  <Route
    key="join-call"
    path="/join-call/:callPermalink"
    element={<NavigateWithParams to="/join-studio/:callPermalink" />}
  />,
  <Route
    key="join-studio"
    path="/join-studio/:callPermalink"
    element={<PublicRoute component={CallRecorder} shouldRedirect={false} />}
  />,
  <Route key="episodes" path="/episodes" element={<PrivateRoute component={Episodes} />} />,
  <Route
    key="create-episodes"
    path="/create-episode"
    element={<PrivateRoute component={CreateEpisode} />}
  />,
  <Route
    key="personalise"
    path="/personalise"
    element={<PrivateRoute component={Personalise} isFullscreen />}
  />,
  <Route key="success" path="/success" element={<PrivateRoute component={PaymentSuccess} />} />,

  <Route key="upload" path="/upload/:episodeId" element={<PrivateRoute component={Upload} />} />,
  <Route
    key="record-call"
    path="/record-call"
    element={<PrivateRoute component={CallRecorder} />}
  />,
  <Route
    key="episode"
    path="/episode/:episodeId"
    element={<PrivateRoute component={EpisodeBuilder} />}
  />,
  <Route key="publish" path="/publish/:episodeId" element={<PrivateRoute component={Publish} />} />,
  <Route
    key="audio-editor"
    path="/audio-editor/:episodeId/:assetId/:uniqueId"
    element={<PrivateRoute component={AudioEditor} />}
  />,
  <Route key="clip-genie" path="/clip-genie" element={<PrivateRoute component={ClipGenie} />} />,
  <Route key="my-account" path="/my-account" element={<PrivateRoute component={MyAccount} />} />,
  <Route key="settings" path="/settings" element={<PrivateRoute component={Settings} />} />,
  <Route key="statistics" path="/statistics" element={<PrivateRoute component={Statistics} />} />,
  <Route
    key="no-subscription"
    path="/no-subscription"
    element={<PrivateRoute component={NoSubscription} />}
  />,
  <Route key="renew" path="/renew" element={<PrivateRoute component={Renew} />} />,
  <Route
    key="renew/processing"
    path="/renew/processing"
    element={<PrivateRoute component={RenewProcessing} />}
  />,
  <Route
    key="music-library"
    path="/music-library"
    element={<PrivateRoute component={UniversalLibrary} />}
  />,
  <Route key="library" path="/library" element={<PrivateRoute component={Library} />} />,
  <Route key="admin" path="/admin" element={<AdminRoute component={AdminUsers} />} />,
  <Route
    key="admin/free-library"
    path="/admin/free-library"
    element={<AdminRoute component={AdminUniversalStorage} />}
  />,
  <Route
    key="admin/support-dashboard/:userId"
    path="/admin/support-dashboard/:userId"
    element={<AdminRoute component={AdminSupportDashboard} />}
  />,
  <Route key="admin/jobs" path="/admin/jobs" element={<AdminRoute component={AdminJobs} />} />,
  <Route
    key="admin/files/:userId"
    path="/admin/files/:userId"
    element={<AdminRoute component={AdminFiles} />}
  />,
  <Route
    key="admin/hosted-shows"
    path="/admin/hosted-shows"
    element={<AdminRoute component={AdminHostedShows} />}
  />,
  <Route
    key="admin/hosting-limit-offenders"
    path="/admin/hosting-limit-offenders"
    element={<AdminRoute component={AdminHostingLimitOffenders} />}
  />,
  <Route key="fallback" path="*" element={<PublicRoute component={Login} />} />,
];
