import lazyWithRetry from './lazyWithRetry';

export const MyAccount = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "account" */
      /* webpackMode: "lazy" */
      'src/pages/my-account/MyAccount'
    ),
);

export const NoSubscription = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "account" */
      /* webpackMode: "lazy" */
      'src/pages/no-subscription/NoSubscription'
    ),
);

export const Renew = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "account" */
      /* webpackMode: "lazy" */
      'src/pages/renew/Renew'
    ),
);

export const RenewProcessing = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "account" */
      /* webpackMode: "lazy" */
      'src/pages/renew/RenewProcessing'
    ),
);

export const ResetPassword = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "account" */
      /* webpackMode: "lazy" */
      'src/pages/login/ResetPassword'
    ),
);

export const Settings = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "settings" */
      /* webpackMode: "lazy" */
      'src/pages/settings/Settings'
    ),
);

// Admin Chunk

export const AdminFiles = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin" */
      /* webpackMode: "lazy" */
      'src/components/admin/FilesList'
    ),
);

export const AdminJobs = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin" */
      /* webpackMode: "lazy" */
      'src/components/admin/Jobs'
    ),
);

export const AdminSupportDashboard = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin" */
      /* webpackMode: "lazy" */
      'src/components/admin/support-dashboard/SupportDashboard'
    ),
);

export const AdminUniversalStorage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin" */
      /* webpackMode: "lazy" */
      'src/components/admin/free-library/FreeLibrary'
    ),
);

export const AdminUsers = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin" */
      /* webpackMode: "lazy" */
      'src/components/admin/UsersTable'
    ),
);

export const AdminHostedShows = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin" */
      /* webpackMode: "lazy" */
      'src/components/admin/HostedShows'
    ),
);

export const AdminHostingLimitOffenders = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "admin" */
      /* webpackMode: "lazy" */
      'src/components/admin/HostingLimitOffenders'
    ),
);

// Call-Recorder Chunk
export const CallRecorder = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "call-recorder" */
      /* webpackMode: "lazy" */
      'src/pages/call-recorder/CallRecorder'
    ),
);

// Episode Chunk
export const AudioEditor = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "audio-editor" */
      /* webpackMode: "lazy" */
      'src/pages/audio-editor/AudioEditorPage'
    ),
);

export const EpisodeBuilder = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "episode-builder" */
      /* webpackMode: "lazy" */
      'src/pages/episode-builder/EpisodeBuilder'
    ),
);

export const Episodes = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "episode" */
      /* webpackMode: "lazy" */
      'src/pages/episodes/EpisodesPage'
    ),
);

export const CreateEpisode = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "episode" */
      /* webpackMode: "lazy" */
      'src/pages/create-episode/CreateEpisode'
    ),
);

export const Upload = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "upload" */
      /* webpackMode: "lazy" */
      'src/pages/upload/Upload'
    ),
);

// Library chunk
export const Library = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "library" */
      /* webpackMode: "lazy" */
      'src/pages/library/Library'
    ),
);

// Clip Genie Chunk
export const ClipGenie = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "clip-genie" */
      /* webpackMode: "lazy" */
      'src/pages/clip-genie/ClipGenie'
    ),
);

export const Publish = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "publish" */
      /* webpackMode: "lazy" */
      'src/pages/publish/Publish'
    ),
);

// Signup Chunk
export const Login = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "login" */
      /* webpackMode: "lazy" */
      'src/pages/login/Login'
    ),
);

export const Signup = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "signup" */
      /* webpackMode: "lazy" */
      'src/pages/signup/Signup'
    ),
);

export const SignupProcessing = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "signup" */
      /* webpackMode: "lazy" */
      'src/pages/signup/SignupProcessing'
    ),
);

export const ForgotPassword = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "login" */
      /* webpackMode: "lazy" */
      'src/pages/login/ForgotPassword'
    ),
);

export const Personalise = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "signup" */
      /* webpackMode: "lazy" */
      'src/pages/signup/Personalise'
    ),
);

// Universal-Library Chunk
export const UniversalLibrary = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "universal-library" */
      /* webpackMode: "lazy" */
      'src/pages/universal-library/UniversalLibrary'
    ),
);

// Welcome Chunk
export const PaymentSuccess = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "welcome" */
      /* webpackMode: "lazy" */
      'src/pages/signup/payment-success/PaymentSuccess'
    ),
);

// Statistics Chunk
export const Statistics = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "statistics" */
      /* webpackMode: "lazy" */
      'src/pages/statistics/StatisticsPage'
    ),
);
