import { useState } from 'react';
import { updateSubscriptionCache } from 'src/apollo/cache/update';
import { usePermissionsContext } from 'src/components/permissions/PermissionsContext';
import YesNoModal from 'src/components/yes-no-modal/YesNoModal';
import { useUpgradeFromTrialMutation } from 'src/generated/graphql';
import useApolloErrorHandler from 'src/hooks/useApolloErrorHandler';

export default function UpgradeSubscriptionModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { permissions } = usePermissionsContext();

  const pluralizedEpisode = permissions?.episodeLimit === 1 ? 'episode' : 'episodes';

  const initialModalText =
    permissions?.episodeLimit ?? 0 > 1
      ? `You can create ${permissions?.episodeLimit} ${pluralizedEpisode} during your trial. Upgrade to a paid plan, and you can create as many episodes as you'd like.`
      : `Your first episode is on us. To create unlimited episodes, you'll need to upgrade to a paid plan.`;

  const [{ noBtnText, yesBtnText, modalText, modalType, modalTitle }, setModalContent] = useState({
    modalTitle: 'Upgrade',
    modalType: 'trial-warning',
    noBtnText: 'No, thanks',
    yesBtnText: 'Upgrade now!',
    modalText: initialModalText,
  });

  const [upgradeFromTrial, { loading, client, error }] = useUpgradeFromTrialMutation({
    onCompleted: res => {
      updateSubscriptionCache({ subscription: res.upgradeFromTrial, client });
      onClose();
    },
  });
  useApolloErrorHandler(error);

  async function onUpgrade() {
    if (modalType === 'trial-warning') {
      setModalContent({
        modalTitle: 'Are you sure?',
        modalType: 'ensure-upgrade',
        noBtnText: 'No',
        yesBtnText: 'Yes, I am sure',
        modalText:
          'Are you sure you want to upgrade your subscription? You will be charged immediately.',
      });
      return;
    }

    upgradeFromTrial();
  }

  return (
    <YesNoModal
      modalTitle={modalTitle}
      isOpen={isOpen}
      noBtnText={noBtnText}
      yesBtnText={yesBtnText}
      modalText={modalText}
      noHandler={onClose}
      yesHandler={onUpgrade}
      disabled={loading}
    />
  );
}
