import { createContext, ReactNode, useContext, useMemo, useEffect } from 'react';
import { useUserCache } from 'src/apollo/cache';
import { Permissions, usePermissionsQuery } from 'src/generated/graphql';
import assert from 'src/utils/assert';

interface PermissionsState {
  permissions?: Permissions | null;
  refetchPermissions: () => void;
  startPolling: (interval: number) => void;
  stopPolling: () => void;
}

const PermissionsContext = createContext<PermissionsState | undefined>(undefined);

export function usePermissionsContext() {
  const context = useContext(PermissionsContext);
  assert(
    context,
    'permissions context must be used as a child of the permissions context provider',
  );
  return context;
}

export function PermissionsContextProvider({ children }: { children: ReactNode }) {
  const user = useUserCache();

  const { refetch, data, startPolling, stopPolling } = usePermissionsQuery({
    fetchPolicy: 'cache-and-network',
  });

  // refetch permissions if user logs in or out
  useEffect(() => {
    refetch();
  }, [user, refetch]);

  const state = useMemo(
    () => ({
      permissions: data?.permissions,
      refetchPermissions: refetch,
      startPolling,
      stopPolling,
    }),
    [data?.permissions, refetch, startPolling, stopPolling],
  );

  return <PermissionsContext.Provider value={state}>{children}</PermissionsContext.Provider>;
}
